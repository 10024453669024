import React from "react";
import { Link } from "gatsby";

export type Product = {
  id: string;
  productName: string;
  slug: string;
};

export type RelatedProductsProps = {
  title: string;
  description?: string;
  products: Array<Product>;
  relatedTo?: Product;
};

export const RelatedProducts = ({
  title,
  description,
  products = [],
  relatedTo
}: RelatedProductsProps) => {
  const filtered = relatedTo
    ? products.filter(
        ({ productName }) => productName !== relatedTo.productName
      )
    : products;

  if (filtered.length === 0) {
    return null;
  }

  return (
    <>
      <h2>{title}</h2>
      {description && <p>{description}</p>}
      <ul>
        {filtered.map(product => (
          <li key={product.id}>
            <Link to={product.slug}>{product.productName}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};
